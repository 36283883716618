import request from "@/utils/http";

// 获取续保险种
export function renewalType(data) {
  return request({
    url: '/Renew/renewalType',
    method: 'post',
    data
  })
}
// 续保费用查询
export function renewalPrice(data) {
  return request({
    url: '/Renew/renewalPrice',
    method: 'post',
    data
  })
}
// 续费投保-获取支付信息
export function renewal(data) {
  return request({
    url: '/Renew/renewal',
    method: 'post',
    data
  })
}
